.course-management-details-option-container {
    display: flex;
    justify-content: flex-end;
}
.course-input {
    // color: #a5a5a5 !important;
    font-family: "Poppins";
    font-size: 12px !important;
    font-weight: 400 !important;
}
.course-mang-edit-btn-margin {
    margin-left: 20px;
}
.input-edit-icon {
    top: 80% !important;
}
.card-shadow {
    box-shadow: 0px 3px 15px rgba(151, 145, 145, 0.28) !important;
}
.loading-state-height {
    height: 388px;
    margin-top: 20px;
}

.loading-update-height {
    height: 500px;
    margin-top: 20px;
}
.image-upload-con {
    width: 200px;
    height: 96px;
    // border: 1px solid #2795ae;
    margin-bottom: 60px;
        label {
            width: 100%;
            height: 100%;
            cursor: pointer;;
        }
        .file-name-typo {
            text-align: center;
            display: block;
        }
}

.download-file-typo{
    color: #2795ae;
    font-weight: 600;
    cursor: pointer;
}
@media (max-width: 767px) {
    .course-modal-resopnsiveness {
        .custom-btn-lg {
            width: 100% !important;
        }
        .custom-btn-lg-secondary {
            margin-top: 5px;
        }
        .custom-btn.custom-btn-lg-secondary {
            min-width: 100%;
        }
    }
}
